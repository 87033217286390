<template>

    <div class="grid">

        <div class="col-12">
            <h3 class="mb-0">Hakediş Ekleme</h3>
            <video width="1200" height="720" controls autoplay>
                  <source src="http://a1catering.com.tr/Content/video/hakedis_ekleme.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
 
        </div>

    </div>
</template>

<script>




</script>


<style scoped>
</style>
